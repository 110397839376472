'use strict';

// Globals
// Notification messages
var alertTimeout;
// Maximum Favourite Products
var maxProducts = $('.max-wishlist-products').val();

// Function to set a cookie
function setCookie(name, value) {
    // Set the cookie with the specified name and value
    document.cookie = name + '=' + value + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;';
}

// Function to get the wishlist from the cookie
function getCookie(name) {
    if (name) {
        // Get all the cookies
        var cookies = document.cookie.split(';');

        // Loop through each cookie
        for (var i = 0; i < cookies.length; i++) {
            // Get the cookie name and value
            var cookie = cookies[i].trim().split('=');
            var cookieName = cookie[0];
            var cookieValue = cookie[1];

            // Check if the cookie name matches the requested name
            if (cookieName === name) {
                // Return the cookie value
                return cookieValue;
            }
        }

        // If the cookie was not found, return an empty string
        return '';
    }
}

// Function to get wishlist form
function showInterestedForm() {
    return new Promise((resolve) => {
            $('.wishlist-form-wrapper').find('.custom-form').load($('.wishlistFormUrl').val(), () => {
            resolve();
        });
    });
}

// Function to get active menu state
function activeMenuState(wishlistLink, wishlistCookie) {
    // Menu link to wishlist page status
    if (wishlistLink) {
        if (wishlistLink.length && wishlistCookie.length) {
            wishlistLink.addClass('active');
        } else {
            wishlistLink.removeClass('active');
        }
    }
}

// Notification messages
function notificationMessages(el, url, type = 'success') {
    var htmlBody = ``;
    // Remove any existing alerts
    if ($('.add-to-wishlist-message').length) {
        $('.add-to-wishlist-message').alert('close');
    }

    // Clear the existing timeout
    if (alertTimeout) {
        clearTimeout(alertTimeout);
    }

    if (type === 'success') {
        htmlBody = `
        <div class="alert alert-info fade show add-to-wishlist-message" role="alert">
            <p>RV added to favourites! For your current browsing session, you can find all you favourites <a href="${url}">here</a>. You can email your favorite RVs to yourself for future reference.</p>
            <button type="button" class="btn btn-light" data-dismiss="alert" aria-label="Close">
                Close
            </button>
        </div>`;
    } else if (type === 'error') {
        htmlBody = `
        <div class="alert alert-warning fade show add-to-wishlist-message" role="alert">
            <p>Oops! Your Favorites list is full. Please remove some RVs to make room for new ones. <a href="${url}">Click Here</a></p>
            <button type="button" class="btn btn-light" data-dismiss="alert" aria-label="Close">
                Close
            </button>
        </div>`;
    }

    $(el).append(htmlBody);

    // Set a new timeout
    alertTimeout = setTimeout(function() {
        $('.add-to-wishlist-message').alert('close');
    }, 10000);
}

// Get Location
function getLocation() {
    var sessionStore = $('.session-store').data('storeid');
    var locationMapping = $('.location-mapping').val();
    locationMapping = locationMapping && JSON.parse(locationMapping);
    var location = locationMapping[sessionStore];

    return location;
}

// Email validation
function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

// Function to initialize wishlist
function initializeWishlist() {
    // Get all the products on the page
    // var products = $('.product');
    var pdp = $('.product-detail');

    // Get the wishlist from the cookie
    var wishlistCookie = getCookie('wishlist');
    var wishlistLink = $('.menu-wishlist');
    var wishlistLinkUrl = wishlistLink.find('a').attr('href');

    activeMenuState(wishlistLink, wishlistCookie);

    // Show form
    if ($('.wishlist-form-wrapper').length) {
        showInterestedForm();
        setTimeout(function () {
            var location = getLocation();
            $('select[name*="Lead.Formstack_Location__c"]').find('option[value="' + location + '"]').attr('selected', 'selected');
            $('textarea[name*="Lead.Description"]').val($('.productsData').val());
        }, 2500);
    }

    $('body').on('click', '.btn-wishlist', function (e) {
        e.preventDefault();
        var productId = pdp.length ? $(this).closest('.product-detail').data('pid') : $(this).closest('.product').data('pid');
        var wishlistItems = wishlistCookie.split('|').length;

        // Check if the product ID is already in the wishlist
        if (wishlistCookie.includes(productId)) {
            // The product is already in the wishlistCookie, so remove it
            if (wishlistCookie.includes(productId) && !wishlistCookie.includes('|')) {
                wishlistCookie = wishlistCookie.replace(productId, '');
            } else {
                wishlistCookie = wishlistCookie.replace(productId + '|', '').replace('|' + productId, '');
            }

            $(this).removeClass('active');

            if ($('#your-favourites').length) {
                var element = $('[data-productid="' + productId + '"]');

                if (element.length > 0) {
                    element.remove();
                } else {
                    console.log('Element with data-productid="' + productId + '" not found.');
                }
            }
        } else {
            var messageContainer = pdp.length ? $(this).closest('.primary-images') : $(this).closest('.product');

            if (wishlistItems < maxProducts && productId !== 'undefined') {
                // The product is not in the wishlist, so add it
                if (wishlistCookie.length > 0) {
                    // If the wishlistCookie already contains products, add a separator before the new product ID
                    wishlistCookie += '|';
                }

                wishlistCookie += productId;
                $(this).addClass('active');

                // Show a message to the user
                // Show Alert
                notificationMessages(messageContainer, wishlistLinkUrl, 'success');
            } else {
                // Show Alert Error
                notificationMessages(messageContainer, wishlistLinkUrl, 'error');
            }
        }

        activeMenuState(wishlistLink, wishlistCookie);
        setCookie('wishlist', wishlistCookie);
    });

    $('body').on('click', '#mainBtn', function (e) {
        e.preventDefault();
        $.spinner().start();
        var isValidEmailid = false;

        var formFirstName = $('#Lead\\.FirstName').val() || null;
        var formLastName = $('#Lead\\.LastName').val() || null;
        var formEmail = $('#Lead\\.Email').val() || null;
        var formLocation = $('#Lead\\.Formstack_Location__c').val() || null;
        isValidEmailid = validateEmail(formEmail);

        if (formFirstName && formLastName && isValidEmailid && formLocation) {
            $.ajax({
                url: $(this).attr('data-url'),
                method: 'get',
                data: {
                    wishlistData: wishlistCookie,
                    formFirstName: formFirstName,
                    formLastName: formLastName,
                    formEmail: formEmail,
                    formLocation: formLocation
                },
                success: function () {
                    $.spinner().stop();
                    $('#btnsubmit').click();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        } else {
            $.spinner().stop();
            $('#btnsubmit').click();
        }
    });
};

module.exports = {
    initializeWishlist,
    getCookie
};
